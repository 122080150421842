exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".kHY2NagM-f0Yc0wTHkDDW {\n  border-bottom: 1px solid #ccc;\n  padding-bottom: 20px;\n}\n.kHY2NagM-f0Yc0wTHkDDW a {\n  padding: 10px;\n  text-transform: uppercase;\n  font-weight: bold;\n}\n.kHY2NagM-f0Yc0wTHkDDW button {\n  padding: 10px;\n  border: none;\n  text-transform: uppercase;\n  background: none;\n  font-weight: bold;\n  font-size: 14px;\n  color: #aaa;\n}\n._3ka3MJxp8SLD6clIBjTU0D {\n  padding-top: 10px;\n  padding-bottom: 20px;\n}\n._3ka3MJxp8SLD6clIBjTU0D a {\n  padding: 10px;\n  text-transform: uppercase;\n  font-weight: bold;\n}\n\n", "", {"version":3,"sources":["/usr/src/src/styles/navigation.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,qBAAqB;CACtB;AACD;EACE,cAAc;EACd,0BAA0B;EAC1B,kBAAkB;CACnB;AACD;EACE,cAAc;EACd,aAAa;EACb,0BAA0B;EAC1B,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;CACb;AACD;EACE,kBAAkB;EAClB,qBAAqB;CACtB;AACD;EACE,cAAc;EACd,0BAA0B;EAC1B,kBAAkB;CACnB","file":"navigation.css","sourcesContent":[".navigation {\n  border-bottom: 1px solid #ccc;\n  padding-bottom: 20px;\n}\n.navigation a {\n  padding: 10px;\n  text-transform: uppercase;\n  font-weight: bold;\n}\n.navigation button {\n  padding: 10px;\n  border: none;\n  text-transform: uppercase;\n  background: none;\n  font-weight: bold;\n  font-size: 14px;\n  color: #aaa;\n}\n.subNavigation {\n  padding-top: 10px;\n  padding-bottom: 20px;\n}\n.subNavigation a {\n  padding: 10px;\n  text-transform: uppercase;\n  font-weight: bold;\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"navigation": "kHY2NagM-f0Yc0wTHkDDW",
	"subNavigation": "_3ka3MJxp8SLD6clIBjTU0D"
};